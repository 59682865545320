<template>
  <div class="observer">
    <slot/>
  </div>
</template>

<script>
  export default {
    props: {
      options: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        observer: null,
      };
    },
    mounted() {
      const options = this.options || { rootMargin: '200px' };
      this.observer = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) this.$emit('intersect');
      }, options);

      this.observer.observe(this.$el);
    },
    destroyed() {
      this.observer.disconnect();
    },
  };
</script>
